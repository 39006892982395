<template>
  <thead>
    <tr class="row">
      <th class="column first_column">
        <VueDatePicker
            v-model="state.date"
            month-picker
            locale="ru"
            :min-date="state.minDate"
            @update:model-value="updateData"
        />
      </th>
      <th class="column" v-for="item in headers()">
        {{ item }}
      </th>
    </tr>
  </thead>
</template>


<script>
import {useState} from '../store';
import axios from "axios";
import {computed, h} from "vue";

export default {
  setup() {
    const state = useState()

    const updateData = computed(async () => {
      axios.get('https://report.k8s.lonsdaleites.ru/api/general/agg/' + state.date.year + "/" + (state.date.month + 1)).then((r) => {
        if (r.status === 200) {
          let _rows = r.data
          let managers = []
          let headers = []
          let data = []

          for (let i = 0; i < _rows.length; i++) {
            let key = Object.keys(_rows[i])[0]
            managers = managers.concat(Object.keys(_rows[i][key]))
            headers.push(key)
          }

          managers = managers.filter((value, index, array) =>
              array.indexOf(value) === index
          )
          managers.sort()

          for (let i = 0; i < managers.length; i++) {
            data.push([])

            for (let j = 0; j < headers.length; j++) {
              let manager = managers[i]
              let header = headers[j]

              if (_rows[j][header][manager] === undefined) {
                data[i].push("")
              }
              else {
                data[i].push(_rows[j][header][manager])
              }
            }
          }
          console.log("data")
          console.log(data)
          state.data = {
            managers: managers,
            headers: headers,
            values: data,
          }
        }
      }).catch((e) => {console.log(e)})
    })

    const headers = () => {
      let state = useState();
      return state.data.headers
    }

    return {
      state: state,
      headers,
      updateData,
    }
  }
};
</script>

<style>
.column {
  display: inline-block;
  text-align: center;
  width: 200px;
  max-width: 300px;
  height: 50px;
  border: 1px solid black;
}
.first_column {
  width: 300px;
  max-width: 500px;
  max-height: 50px;
}

.row {
  max-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
</style>