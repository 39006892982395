<!--v-bind:style="{ backgroundColor: row['color']}"-->
<template>
  <tr
      class="cells"
      v-for="manager in data.managers.length"
  >
    <td class="cell first_cell">
      <div class="cell_div">
        {{ data.managers[manager] }}
      </div>
    </td>
    <td class="cell" v-for="value in data.values[manager]">
      <div class="cell_div">
        {{ value }}
      </div>
    </td>
  </tr>
</template>

<script>
import {useState} from '../store';
import {computed} from "vue";

export default {

  setup() {
    let state = useState();

    const data = computed(() => {
      console.log(state.data);
      return state.data
    })


    return {
      state,
      data,
    }
  }
}
;
</script>

<style>
.cell {
  display: inline-block;
  text-align: center;

  width: 200px;
  max-width: 300px;
  border: 1px solid black;
  height: 50px;
}

.first_cell {
  width: 300px;
  max-width: 500px;
  border-right: 1px solid black;
  border-left: 1px solid black;
}

.cells {
  max-height: 50px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.cells:hover {
  background-color: lavenderblush;
}

.cell_div {
  display: inline-block;
  margin-top: 10px;
}

</style>